export default function() {

    // MENU (both pages)

    const menu = document.querySelector('.header-upper__menu'),
          menuItem = document.querySelector('.header-upper__menu-item'),
          menuButton = document.querySelector('.header-upper__burgir'),
          menuLines = document.querySelectorAll('.header-upper__burgir-line'),
          fog = document.querySelector('.fog');
    let menuClicked = false;

    
    function closeMenu() {
        // burgir lines styles
        menuLines[0].classList.remove('header-upper__burgir-line-1_active');
        menuLines[1].classList.remove('header-upper__burgir-line-2_active');
        menuLines[2].classList.remove('header-upper__burgir-line-3_active');

        // remove the background fog
        fog.classList.remove('fog_active');
        setTimeout(function() {
            fog.classList.remove('fog_show');
        }, 400);

        // hide the menu
        menu.classList.remove('header-upper__menu_active');

        // unlock document scroll
        document.body.classList.remove('scroll_lock');

        menuClicked = false;
        document.removeEventListener('click', x);
    }

    function x(e) {
        if (menu.classList.contains('header-upper__menu_active') && e.target != menu && e.target != menuItem) {
            closeMenu();
        }
    }


    menuButton.addEventListener('click', e => {
        if (!menuClicked) {
            // burgir lines styles
            menuLines[0].classList.add('header-upper__burgir-line-1_active');
            menuLines[1].classList.add('header-upper__burgir-line-2_active');
            menuLines[2].classList.add('header-upper__burgir-line-3_active');

            // put on the background fog
            fog.classList.add('fog_show');
            setTimeout(function() {
                fog.classList.add('fog_active');
            }, 6);

            // show the menu
            menu.classList.add('header-upper__menu_active');

            // lock document scroll
            document.body.classList.add('scroll_lock');
            
            // listener for clicks outside of the menu
            setTimeout(function() {
                document.addEventListener('click', x);
                menuClicked = true;
            }, 500);
        } else {
            closeMenu();
        }
    });



    
    
    // BACKGROUND IMAGE CHANGE

    function timer(callback, delay) {
        var id, started, remaining = delay, running;
        
        this.start = function() {
            running = true;
            started = new Date();
            id = setTimeout(callback, remaining);
        };
    
        this.pause = function() {
            running = false;
            clearTimeout(id);
            remaining -= new Date() - started;
        };
    
        this.getTimeLeft = function() {
            if (running) {
                this.pause();
                this.start();
            }
            return remaining;
        };
    
        this.getStateRunning = function() {
            return running;
        };

        this.start();
    }

    const backgrounds = document.querySelectorAll('.header__background'),
          mainBox = document.querySelector('.header-main'),
          title = document.querySelector('.header-main__title'),
          text = document.querySelector('.header-main__text'),
          button = document.querySelector('.header-main__button'),
          ad = document.querySelector('header .programmes-wrapper'),
          otherField = document.querySelector('.header-main-other'),
          pagination = document.querySelectorAll('.header-pagination__item');
          
        
    var current = 0,
        old = false,
        oldHadParallel = false,
        animationName = '',
        textContent = '',
        paginationSelect = false,
        paginationSelectedPage,
        timerNextBg,
        timerNextBg2,
        timerNextBg3,
        timerShowSlideContent,
        pausedByUser = false,
        goNext = true,
        runningNextBackground = false,
        nextBgWasRunningHidden = false,
        theTimerPaused;
    
    // ВНИМАНИЕ свести установку всех transitions сюда, в идеале и для таймеров через переменные
    // ВНИМАНИЕ ¿ слайды с рекламой вывести в массив ? в общем как нибудь чтобы тоже там не сидело голое число и отсюда можно было поменять
    

    // backgrounds.forEach((item, index) => {
    //     if (index % 2 == 1) {
    //         item.style.animation = 'netflixRight 10s infinite linear';
    //     }
    //     if (index % 2 == 0) {
    //         item.style.animation = 'netflixLeft 10s infinite linear';
    //     }
    // });

    // Set content for the first time (only)
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == 'hidden') {

            // if slide wasn't paused, then pause it. do nothing if already was
            if (!runningNextBackground && backgrounds[current].style.animationPlayState !== 'paused') {
                // console.log('hidden, pausing...');
                backgrounds[current].style.animationPlayState = 'paused';
                if (oldHadParallel) {
                    document.querySelector(`.${backgrounds[current].className.slice(19) + '__parallel'}`).style.animationPlayState = 'paused';
                }
                // goNext = false;
                killBgTimers();
                clearTimeout(timerShowSlideContent);
                pagination[current].classList.add('header-pagination__item_paused');
                // setTimeout(function(){
                //     console.log(backgrounds[current].style.animationPlayState);
                // }, 1500);
            } else if (runningNextBackground) {
                nextBgWasRunningHidden = true;
                goNext = false;
                killBgTimers();
                // switch to var to know it was running to use when back to 'visible'
            }

        } else if (document.visibilityState == 'visible') {

            // console.log('visible, resuming...');
            if (!pausedByUser && !nextBgWasRunningHidden) {
                backgrounds[current].style.animationPlayState = 'running';
                if (oldHadParallel) {
                    document.querySelector(`.${backgrounds[current].className.slice(19) + '__parallel'}`).style.animationPlayState = 'running';
                }
                goNext = true;
                // ВНИМАНИЕ тут таймеры другие могли быть запущены при остановке, но это пока не так важно
                // console.log(theTimerPaused.getTimeLeft());
                theTimerPaused.start();
                pagination[current].classList.remove('header-pagination__item_paused');
            } else if (nextBgWasRunningHidden) {
                goNext = true;
                nextBgWasRunningHidden = false;
                // console.log('running nextBackround after coming back!');
                nextBackground();
            }

        }
    });

    function killBgTimers() {
        if (timerNextBg) {
            // console.log("~ timerNextBg.getStateRunning:", timerNextBg.getStateRunning());
        }
        if (timerNextBg2) {
            // console.log("~ timerNextBg2.getStateRunning:", timerNextBg2.getStateRunning());
        }
        if (timerNextBg3) {
            // console.log("~ timerNextBg3.getStateRunning:", timerNextBg3.getStateRunning());
        }

        if (timerNextBg && timerNextBg.getStateRunning()) {
            timerNextBg.pause();
            // console.log('timerNextBg paused.');
            theTimerPaused = timerNextBg;
        } else {
            // console.log("timerNextBg apparently wasn't running");
        }
        if (timerNextBg2 && timerNextBg2.getStateRunning()) {
            timerNextBg2.pause();
            // console.log('timerNextBg2 paused.');
            theTimerPaused = timerNextBg2;
        } else {
            // console.log("timerNextBg2 apparently wasn't running");
        }
        if (timerNextBg3 && timerNextBg3.getStateRunning()) {
            timerNextBg3.pause();
            // console.log('timerNextBg3 paused.');
            theTimerPaused = timerNextBg3;
        } else {
            // console.log("timerNextBg3 apparently wasn't running");
        }
    }

    setTimeout(function() {
        mainBox.style.opacity = '1';
    }, 1500);
    setTimeout(function() {
        text.style.opacity = '1';
        if (button) {
            button.style.opacity = '1';
        }
    }, 2500);

    // Launch timer for the first slide change
    timerNextBg = new timer(function() {
        // console.log('nextbackground() initial call');
        nextBackground();
    }, 8000);


    // Pagination dots listener
    pagination.forEach((item, index) => {
        item.addEventListener('click', function() {

            // if nextBackground() not in a process by paginationSelect, or page selected !== being showed
            if (!paginationSelect && index !== current) {
                // console.log('pagination click for another');
                paginationSelect = true;
                paginationSelectedPage = index;
                // console.log('nextbackground() 204 call');
                goNext = true;
                nextBackground();
            } else if (index == current) {
                // console.log('pagination click for a pause');
                // pause/play the slide if page selected == being showed
                paginationSelect = false;

                // if wasn't already paused, then pause the slide
                if (backgrounds[current].style.animationPlayState !== 'paused') {
                    backgrounds[current].style.animationPlayState = 'paused';
                    if (oldHadParallel) {
                        document.querySelector(`.${backgrounds[current].className.slice(19) + '__parallel'}`).style.animationPlayState = 'paused';
                    }
                    pausedByUser = true;
                    goNext = false;
                    // killBgTimers();
                    // clearTimeout(timerShowSlideContent);
                    pagination[current].classList.add('header-pagination__item_paused');

                } else {
                    // if was already paused, then get the slide running again
                    backgrounds[current].style.animationPlayState = 'running';
                    if (oldHadParallel) {
                        document.querySelector(`.${backgrounds[current].className.slice(19) + '__parallel'}`).style.animationPlayState = 'running';
                    }
                    pausedByUser = false;
                    goNext = true;
                    timerNextBg.start();
                    pagination[current].classList.remove('header-pagination__item_paused');
                }
            }
        });
    });


    // Slide change function
    function nextBackground() {

        // console.log('nextBackground()');

        if (goNext == false) {
            return;
        }

        runningNextBackground = true;

        // kill timers if there was any (probably yes bc they may be launched from several places)
        killBgTimers();
        clearTimeout(timerShowSlideContent);
        
        // change slide counters
        if (!paginationSelect) {
            // if autochange
            old = current;
            current++;
            current = current % backgrounds.length;
        } else if (paginationSelect) {
            // if specific page was selected
            old = current;
            current = paginationSelectedPage;
        }

        // hide ad or title (if shown)...
        try {
            // console.log('trying to hide things...');
            if (ad && getComputedStyle(ad).opacity > '0') {
                ad.addEventListener('transitionend', nextBg1);
                ad.style.opacity = '0';
                function nextBg1() {
                    ad.style.display = 'none';
                    ad.removeEventListener('transitionend', nextBg1);
                }
            }
            if (mainBox && getComputedStyle(mainBox).opacity > '0') {
                mainBox.addEventListener('transitionend', nextBg2);
                mainBox.style.opacity = '0';
                function nextBg2() {
                    text.style.opacity = '0';
                    if (button) {
                        button.style.opacity = '0';
                    }
                    mainBox.style.display = 'none';
                    mainBox.removeEventListener('transitionend', nextBg2);
                }
            }
            if (otherField && getComputedStyle(otherField).opacity > '0') {
                otherField.addEventListener('transitionend', nextBg3);
                otherField.style.opacity = '0';
                function nextBg3() {
                    otherField.style.display = 'none';
                    otherField.removeEventListener('transitionend', nextBg3);
                }
            }
        } finally {
            // ...then proceed to actual image switch
            setTimeout(changeImage, 1000);
        }

        // switch the background image
        function changeImage() {
            // console.log('changeImage()');
            backgrounds[old].style.opacity = '0';
            pagination[old].classList.remove('header-pagination__item_active');
            backgrounds.forEach(item => {
                item.style.animationPlayState = 'unset';
            });
            // if previous image had a parallel one
            if (oldHadParallel) {
                // console.log('oldHadParallel is true, old bgimage index is:', old);
                document.querySelector(`.${backgrounds[old].className.slice(19) + '__parallel'}`).style.opacity = '0';
                setTimeout(function() {
                    document.querySelector(`.${backgrounds[old].className.slice(19) + '__parallel'}`).style.animationName = 'none';
                }, 1000);
                oldHadParallel = false;
            }

            setTimeout(function() {
                // set animation direction for a new image
                try {
                    switch (current) {
                        case 0:
                        case 2:
                        case 4:
                            animationName = 'netflixLeft';
                            break;
                        case 1:
                        case 3:
                            animationName = 'netflixRight';
                            break;
                    }
                } finally {
                    backgrounds[current].style.animationName = `${animationName}`;
                }

                // remove animation direction for an old image
                backgrounds[old].style.animationName = 'none';
                // backgrounds[old].style.clipPath = 'none';

                // if current image has a parallel one, write it, give it animation direction and show it
                let parallelBgImage = backgrounds[current].className.slice(19) + '__parallel';
                if (document.querySelector(`.${parallelBgImage}`)) {
                    // console.log('There is a parallel image to the current one.');
                    const x = document.querySelector(`.${parallelBgImage}`);
                    x.style.animationName = 'netflixRight';
                    // backgrounds[current].style.clipPath = 'polygon(0 0, 60% -5%, 39% 100%, 0 100%)';
                    x.style.opacity = '1';
                    oldHadParallel = true;
                }

                // show current image
                backgrounds[current].style.opacity = '1';
                pagination[current].classList.add('header-pagination__item_active');
                if (document.querySelector('.header-pagination__item_paused')) {
                    document.querySelector('.header-pagination__item_paused').classList.remove('header-pagination__item_paused');
                }

                // proceed to showing slide content (title, texts, buttons and logos)
                timerShowSlideContent = setTimeout(showSlideContent, 2000);
            }, 1000);
        }

        // Show current slide content
        function showSlideContent() {
            // console.log('showSlideContent()');

            if (window.location.pathname.includes('martial') && current == 1) {
                // if martial.html ВНИМАНИЕ надо будет тут в целом с текстами внизу поумнее как-то сделать, также чтобы не писать изначальные для index==0 тексты в html файлах
                // show the ad
                if (mainBox && getComputedStyle(mainBox).display == 'block') {
                    // ВНИМАНИЕ это заплатка, убрать поскорее. В большинстве случаев во время теста, когда я на переключении с последнего слайда на первый делал hidden и потом обратно visible, вызывался дальше уже второй слайд и оставался mainBox, стакаясь сверху ad. mainbox opacity в этих случаях по какой-то причине 0, что то не ухватил там
                    mainBox.style.display = 'none';
                }
                ad.style.display = 'flex';
                let x = setInterval(function() {
                    if (getComputedStyle(ad).display == 'flex') {
                        ad.style.opacity = '1';
                        clearInterval(x);
                    }
                }, 5);
            } else {
                // show mainBox that contains title, texts and such
                // also set that titles and texts
                mainBox.style.display = 'block';
                // ВНИМАНИЕ why is there an interval here?
                let y = setInterval(function() {
                    if (getComputedStyle(mainBox).display == 'block') {
                        mainBox.style.opacity = '1';
                        try {
                            if (window.location.pathname.includes('martial')) {
                                // if martial.html
                                switch (current) {
                                    case 0:
                                        textContent = 'У нас занимаются: <br>Дети от 3 лет &#160• Подростки &#160• Женщины и Мужчины всех возрастов';
                                        break;
                                    case 2:
                                        textContent = 'Наши боевые направления:<br>Бокс &#160• Кикбоксинг &#160• Тайский бокс &#160• ММА &#160• Самбо &#160• Дзюдо &#160• Карате &#160• Ушу &#160• Капоэйра';
                                        break;
                                    case 3:
                                        textContent = 'Наши фитнес направления:<br>Стретчинг &#160• ЛФК &#160• Йога';
                                        break;
                                    case 4:
                                        textContent = 'Наши специалисты помогут плавно увеличить нагрузку для достижения вашей лучшей формы и крепкого здоровья';
                                        break;
                                }
                            } else {
                                // if index.html
                                title.innerHTML = 'Многопрофильный<br> молодёжный<br> центр «Рух»';
                                button.style.display = 'block';
                                switch (current) {
                                    case 0:
                                        textContent = 'Интересные занятия для детей, подростков, взрослых и семей';
                                        break;
                                    case 1:
                                        // title.innerHTML = '';
                                        textContent = 'Лаборатория робототехники и программирования в IT-центре «ROBODOM»';
                                        // otherField.innerHTML = '';
                                        // otherField.insertAdjacentHTML('beforeend', `<img aria-hidden="true" src="./icons/robodomLogo.png" class="header-main-other__logo robodom">`);
                                        // otherField.insertAdjacentHTML('beforeend', `<img aria-hidden="true" src="./icons/ruhlogo.svg" class="header-main-other__logo ruh">`);
                                        // button.style.display = 'none';
                                        // otherField.style.display = 'flex';
                                        break;
                                    case 2:
                                        textContent = 'Спортивный клуб с двумя рингами и тренажёрной зоной';
                                        break;
                                    case 3:
                                        textContent = '&#160';
                                        break;
                                }
                            }
                        } finally {
                            text.innerHTML = `${textContent}`;
                        }
                        setTimeout(function() {
                            text.style.opacity = '1';
                            if (current == 1) {
                                // ВНИМАНИЕ для объявления о лагере. лучше по другому совсем это сделать
                                if (otherField) {
                                    otherField.style.opacity = '1';
                                }
                            } else {
                                if (button) {
                                    button.style.opacity = '1';
                                }
                            }
                        }, 1000);
                        clearInterval(y);
                    }
                }, 5);
    
            }

            runningNextBackground = false;

            // continue to next slide after
            if (current == 1 && !window.location.pathname.includes('martial')) {
                // if index.html second slide (with ad)
                timerNextBg2 = new timer(function() {
                    // console.log('nextbackground() from timerNextBg2 is executing...');
                    nextBackground();
                }, 10000);
            } else {
                // if all other slides on both pages
                timerNextBg3 = new timer(function() {
                    // console.log('nextbackground() from timerNextBg3 is executing...');
                    nextBackground();
                }, 7500);
            }

            paginationSelect = false;

        }

    }



    // CORNER MESSAGE (works on index.html only)
    if (document.querySelector('.imessage')) {
        setTimeout(() => {
            document.querySelector('.imessage').style.display = 'flex';
            setTimeout(() => {
                document.querySelector('.imessage').style.opacity = '1';
            }, 100);
        }, 10000);
        setTimeout(() => {
            document.querySelector('.imessage__cross').style.opacity = '0.7';
    
            document.querySelector('.imessage__cross').addEventListener('click', (event) => {
                document.querySelector('.imessage').style.opacity = '0';
                setTimeout(() => {
                    document.querySelector('.imessage').style.display = 'none';
                }, 400);
            });
        }, 12500);
    }



}
export default function() {

    // if page is not martial.html
    if (!window.location.pathname.includes('martial')) {
        return;
    }

    const groupCards = document.querySelectorAll('.price-group__item'),
          individCards = document.querySelectorAll('.price-individ__item'),
          otherCards = document.querySelectorAll('.price-other__item'),
          subsCards = document.querySelectorAll('.price-subs__item'),
          groupRelayNumbersWrapper = document.querySelector('.price-group .relay-slides'),
          individRelayNumbersWrapper = document.querySelector('.price-individ .relay-slides'),
          otherRelayNumbersWrapper = document.querySelector('.price-other .relay-slides'),
          subsRelayNumbersWrapper = document.querySelector('.price-subs .relay-slides'),
          relays = document.querySelectorAll('.relay');

    const groupCarousel = document.querySelector('.price-group__wrapper'),
          individCarousel = document.querySelector('.price-individ__wrapper'),
          otherCarousel = document.querySelector('.price-other__wrapper'),
          subsCarousel = document.querySelector('.price-subs__wrapper');


    const cardsArray = [groupCards, individCards, otherCards, subsCards],
          relaysArray = [groupRelayNumbersWrapper, individRelayNumbersWrapper, otherRelayNumbersWrapper, subsRelayNumbersWrapper],
          carouselsArray = [groupCarousel, individCarousel, otherCarousel, subsCarousel];



    // Append slides numbers
    cardsArray.forEach((item, index) => {
        for (let i = 1; i < item.length + 1; i++) {
            if (i == 1) {
                relaysArray[index].insertAdjacentHTML('beforeend', `<div class="relay-slides__item relay-slides__item_active">${i}</div>`);
            } else {
                relaysArray[index].insertAdjacentHTML('beforeend', `<div class="relay-slides__item">${i}</div>`);
            }
        }
    });

    // Initiate slick-carousels
    $(groupCarousel).slick({
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        slidesToShow: 2,
        swipe: false,
        touchMove: false,
        arrows: false,
        infinite: false
    });
    $(individCarousel).slick({
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        slidesToShow: 2,
        swipe: false,
        touchMove: false,
        arrows: false,
        infinite: false
    });
    $(otherCarousel).slick({
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        slidesToShow: 3,
        swipe: false,
        touchMove: false,
        arrows: false
    });
    $(subsCarousel).slick({
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        slidesToShow: 3,
        swipe: false,
        touchMove: false,
        arrows: false,
        infinite: false
    });
    // $(aboutCarousel).slick({
    //     // variableWidth: true,
    //     rows: 2,
    //     slidesPerRow: 2,
    //     swipe: false,
    //     touchMove: false,
    //     arrows: false
    // });

    // Clicks on arrows and slide numbers
    relays.forEach((item) => {
        item.addEventListener('click', (event) => {
            if (item.previousElementSibling == groupCarousel || item.previousElementSibling == individCarousel || item.previousElementSibling == otherCarousel || item.previousElementSibling == subsCarousel) {

                // click on numbers
                if (event.target && event.target.matches('.relay-slides__item')) {
                    for (let i = 0; i < event.target.parentElement.children.length; i++) {
                        // remove all _active classes
                        event.target.parentElement.children[i].classList.remove('relay-slides__item_active');
                        // detect the clicked number...
                        if (event.target == event.target.parentElement.children[i]) {
                            // ...switch to a corresponding slick slide
                            $(item.previousElementSibling).slick('slickGoTo', i);
                            // ...and give _active class to that number
                            event.target.parentElement.children[i].classList.add('relay-slides__item_active');
                        }
                    }
                }

                // Click on left arrow
                if (event.target && event.target.matches('.relay-left')) {
                    // remove all _active classes 
                    for (let i = 0; i < event.target.nextElementSibling.children.length; i++) {
                        event.target.nextElementSibling.children[i].classList.remove('relay-slides__item_active');
                    }

                    // workaround для slick каруселей с <= 3 слайдами. с помощью этого просиходит изменение слайдов когда их <= 3. лучше пофиксить сам slick
                    // далее в else обычная работа всех остальных
                    if (event.target.parentElement.previousElementSibling == otherCarousel && event.target.parentElement.previousElementSibling == subsCarousel) {

                        const currentSlideIndex = $(event.target.parentElement.previousElementSibling).slick('slickCurrentSlide');
                        let x;
                        if (currentSlideIndex == 0) {
                            x = 1;
                        } if (currentSlideIndex == 1) {
                            x = 0;
                        }

                        // ...switch to a corresponding slick slide
                        $(item.previousElementSibling).slick('slickGoTo', x);
                        // ...and give _active class to that number
                        event.target.parentElement.children[x].classList.add('relay-slides__item_active');

                    } else {

                        // switch slide
                        $(item.previousElementSibling).slick('slickPrev');

                    }
                    

                    // give active class to number
                    const currentSlideIndex = $(event.target.parentElement.previousElementSibling).slick('slickCurrentSlide');
                    const currentNumber = event.target.nextElementSibling.children[currentSlideIndex];
                    currentNumber.classList.add('relay-slides__item_active');

                }

                // Click on right arrow
                if (event.target && event.target.matches('.relay-right')) {
                    
                    // remove all _active classes
                    for (let i = 0; i < event.target.previousElementSibling.children.length; i++) {
                        event.target.previousElementSibling.children[i].classList.remove('relay-slides__item_active');
                    }

                    // workaround для slick каруселей с <= 3 слайдами. с помощью этого просиходит изменение слайдов когда их <= 3. лучше пофиксить сам slick
                    // далее в else обычная работа всех остальных
                    if (event.target.parentElement.previousElementSibling == otherCarousel && event.target.parentElement.previousElementSibling == subsCarousel) {

                        const currentSlideIndex = $(event.target.parentElement.previousElementSibling).slick('slickCurrentSlide');
                        let x;
                        if (currentSlideIndex == 0) {
                            x = 1;
                        } if (currentSlideIndex == 1) {
                            x = 0;
                        }

                        // ...switch to a corresponding slick slide
                        $(item.previousElementSibling).slick('slickGoTo', x);
                        // ...and give _active class to that number
                        event.target.parentElement.children[x].classList.add('relay-slides__item_active');

                    } else {

                        // switch slide
                        $(item.previousElementSibling).slick('slickNext');

                    }

                    
                    // give active class to number
                    const currentWrapper = event.target.parentElement.previousElementSibling;
                    const currentSlideIndex = $(currentWrapper).slick('slickCurrentSlide');
                    const currentNumber = event.target.previousElementSibling.children[currentSlideIndex];
                    currentNumber.classList.add('relay-slides__item_active');
                }

            }
        });
    });

}
// import Splide from '@splidejs/splide';
// import Grid from '@splidejs/splide-extension-grid';
// import Intersection from '@splidejs/splide-extension-intersection';

export default function() {

    // if page is not martial.html
    if (!window.location.pathname.includes('martial')) {
        return;
    }

    // // Splide carousel with thumbnail
    // var main = new Splide( '.about-images__main', {
    //     fixedHeight: 450, 
    //     type      : 'fade',
    //     rewind    : true,
    //     pagination: false,
    //     arrows    : false,
    //     autoplay: 'play',
    //     intersection: {
    //       inView: {
    //         autoplay: true,
    //       },
    //       outView: {
    //         autoplay: false,
    //       },
    //     },
    // });

    // var thumbnail = new Splide( '.about-images__thumbnail', { 
    //     fixedWidth : 100,
    //     fixedHeight: 60,
    //     gap        : 10,
    //     rewind     : true,
    //     pagination : false,
    //     isNavigation: true,
    //     focus      : 'center',
    //     breakpoints: {
    //       600: {
    //         fixedWidth : 60,
    //         fixedHeight: 44,
    //       },
    //     },
    // });

    // // instersection with viewport not working
    // main.on( 'intersection:in', function ( entry ) {
    //     console.log( 'in', entry.target );
    // });
    // main.on( 'intersection:out', function ( entry ) {
    //     console.log( 'out', entry.target );
    // });


    // main.sync(thumbnail);
    // main.mount(Intersection);
    // thumbnail.mount(Intersection);





    // Text scripts

    // Fn of adding active classes to text and arrow
    function addItemActiveClasses(element) {
        element.nextElementSibling.classList.add('about-info__wrapper-text_active');
        element.querySelector('.about-info__wrapper-arrow').classList.add('about-info__wrapper-arrow_active');
        // element.parentElement.style.maxHeight = 'fit-content';
    }

    // Fn of removing active classes from text and arrow
    function removeItemActiveClasses() {
        if (document.querySelector('.about-info__wrapper-text_active')) {
            document.querySelector('.about-info__wrapper-text_active').classList.remove('about-info__wrapper-text_active');
            document.querySelector('.about-info__wrapper-arrow_active').classList.remove('about-info__wrapper-arrow_active');
        }
    }


    const wrappers = document.querySelectorAll('.about-info__wrapper-title');

    wrappers.forEach((item) => {

        item.addEventListener('click', event => {

            console.log(event.target);
            
            if (event.target) {
                removeItemActiveClasses();
                addItemActiveClasses(event.target);
            } else {
                removeItemActiveClasses();
            }
        
        });

    });

}
export default function() {

  // if page is not martial.html
  if (!window.location.pathname.includes('martial')) {
    return;
  }

  const relayButtonLeft = document.querySelector('.gallery .relay-left'),
        relayButtonRight = document.querySelector('.gallery .relay-right');


        relayButtonRight.addEventListener('click', (event) => {
          relayButtonRight.style.display = 'none';

          starter = document.elementFromPoint(event.clientX, event.clientY);

          $(starter).click();
        });

}
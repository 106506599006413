export default function() {

    // if page is not martial.html
    if (!window.location.pathname.includes('martial')) {
        return;
    }
    
    const trainersName = document.querySelector('.trainers__card-name'),
          trainersImage = document.querySelector('.trainers__card-image'),
          trainersDesc = document.querySelector('.trainers__card-desc'),
          trainersText = document.querySelector('.trainers__card-text'),
          buttonLeft = document.querySelector('.trainers .relay-left'),
          buttonRight = document.querySelector('.trainers .relay-right'),
          numbersWrapper = document.querySelector('.trainers .relay-slides');
    let counter = 0,
        data,
        dataLength,
        intervalChangeCards,
        timeoutChangeCards;
          
    const request = new XMLHttpRequest();
    request.open('GET', './../db.json');
    request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    request.send();
    request.addEventListener('load', () => {
        if (request.status === 200) {
            data = JSON.parse(request.response);
            dataLength = Object.keys(data.trainers).length;

            // Set the first slide
            counter = 0;
            setSlide();
        }
    });

    
    // Switch slide to
    function setSlide() {
        // console.log('setSlide fn started');
        counter = counter % dataLength;
        // console.log(counter);
        trainersName.innerHTML = data.trainers[counter].name;
        trainersImage.src = data.trainers[counter].img;
        trainersDesc.innerHTML = data.trainers[counter].desc;
        trainersText.innerHTML = data.trainers[counter].text;

        for (let i = 0; i < numbersWrapper.children.length; i++) {
            // remove all _active classes
            numbersWrapper.children[i].classList.remove('relay-slides__item_active');
        }
        // ...and give _active class to that element
        numbersWrapper.children[counter].classList.add('relay-slides__item_active');
    }




    // Set slides numbers
    function setNumbers() {
        // по какой то причине объект data тут не видно!
        // console.log(Object.keys(data.trainers).length);
        for (let i = 1; i <= 12; i++) {
            // console.log(dataLength);
            if (i == 1) {
                numbersWrapper.insertAdjacentHTML('beforeend', `<div class="relay-slides__item relay-slides__item_active">${i}</div>`);
            } else {
                numbersWrapper.insertAdjacentHTML('beforeend', `<div class="relay-slides__item">${i}</div>`);
            }
        }
    }
    setNumbers();

    // Auto switch trainers cards on interval
    function changeCards() {
        // console.log('card changed');
        counter++;
        counter = counter % dataLength;
        setSlide();
    }

    function startIntervalChangeCards() {
        // console.log('interval started');
        clearInterval(intervalChangeCards);
        intervalChangeCards = setInterval(changeCards, 5000);
    }
    startIntervalChangeCards();

    function startTimeoutChangeCards() {
        // console.log('timeout interval');
        clearInterval(intervalChangeCards);
        clearTimeout(timeoutChangeCards);
        timeoutChangeCards = setTimeout(startIntervalChangeCards, 10000);
    }


    buttonLeft.addEventListener('click', (event) => {
        // console.log('buttonLeft clicked');
        startTimeoutChangeCards();
        if (counter == 0) {
            counter = dataLength - 1;
            
        } else {
            counter--;
        }
        setSlide();

        for (let i = 0; i < numbersWrapper.children.length; i++) {
            // remove all _active classes
            numbersWrapper.children[i].classList.remove('relay-slides__item_active');
        }

        // ...and give _active class to that element
        numbersWrapper.children[counter].classList.add('relay-slides__item_active');
    });

    buttonRight.addEventListener('click', (event) => {

        startTimeoutChangeCards();
        if (counter == dataLength - 1) {
            counter = 0;
        } else {
            counter++;
        }
        setSlide();
        
        for (let i = 0; i < numbersWrapper.children.length; i++) {
            // remove all _active classes
            numbersWrapper.children[i].classList.remove('relay-slides__item_active');
        }
        // ...and give _active class to that element
        numbersWrapper.children[counter].classList.add('relay-slides__item_active');
    });

    // slideNumbers.forEach((item, index) => {
    //     item.addEventListener('click', () => {
    //         console.log(`${index} slideNum clicked`);
    //         startTimeoutChangeCards();
    //         counter = index;
    //         setSlide();
    //     });
    // });


    numbersWrapper.addEventListener('click', (event) => {
        if (event.target && event.target.matches('.relay-slides__item')) {
            for (let i = 0; i < numbersWrapper.children.length; i++) {

                // remove all _active classes
                numbersWrapper.children[i].classList.remove('relay-slides__item_active');

                // detect the clicked element...
                if (event.target == numbersWrapper.children[i]) {

                    counter = i;
                    startTimeoutChangeCards();
                    setSlide();

                    // ...and give _active class to that element
                    numbersWrapper.children[i].classList.add('relay-slides__item_active');
                }
            }
        }
    });

}
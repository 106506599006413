export default function() {

    const boxingWrapper = document.querySelector('.boxArea .schedule__wrapper'),
          boxingWeekdays = document.querySelectorAll('.boxArea .schedule__weekdays-item'),
          wrestlingWrapper = document.querySelector('.wrestlingArea .schedule__wrapper'),
          wrestlingWeekdays = document.querySelectorAll('.wrestlingArea .schedule__weekdays-item');

    let data,
        boxingMondayLength,
        wrestlingMondayLength;

    const request = new XMLHttpRequest();
    request.open('GET', './../db.json');
    request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    request.send();
    request.addEventListener('load', () => {
        if (request.status === 200) {
            data = JSON.parse(request.response);
            boxingMondayLength = Object.keys(data.schedule.boxing.monday).length;
            wrestlingMondayLength = Object.keys(data.schedule.wrestling.monday).length;

            // Insert info about Monday at the beggining
            function setBoxSchedule() {
                for (let i = 0; i <= boxingMondayLength - 1; i++) {

                    var message = `${data.schedule.boxing.monday[i].title} ` + `${data.schedule.boxing.monday[i].time} ` + `${data.schedule.boxing.monday[i].trainer} ` + `${data.schedule.boxing.monday[i].age}`;
                    var encodedMessage = encodeURIComponent(message);
                    var messageStart = '%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%3A%0A';
                    var readyEncodedMessage = messageStart + encodedMessage;

                    boxingWrapper.insertAdjacentHTML('beforeend', `<div class="schedule__wrapper-item">
                        <div class="schedule__wrapper-item__title">${data.schedule.boxing.monday[i].title}</div>
                        <div class="schedule__wrapper-item__time">${data.schedule.boxing.monday[i].time}</div>
                        <div class="schedule__wrapper-item__trainer">${data.schedule.boxing.monday[i].trainer}</div>
                        <div class="schedule__wrapper-item__age">${data.schedule.boxing.monday[i].age}</div>
                        <div class="schedule__wrapper-item__button"><a href=https://wa.me/79274441844?text=${readyEncodedMessage}>Записаться</a></div>
                    </div>`);

                }
            }
            function setWrestSchedule() {
                for (let i = 0; i <= wrestlingMondayLength - 1; i++) {

                    var message = `${data.schedule.wrestling.monday[i].title} ` + `${data.schedule.wrestling.monday[i].time} ` + `${data.schedule.wrestling.monday[i].trainer} ` + `${data.schedule.wrestling.monday[i].age}`;
                    var encodedMessage = encodeURIComponent(message);
                    var messageStart = '%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%3A%0A';
                    var readyEncodedMessage = messageStart + encodedMessage;

                    wrestlingWrapper.insertAdjacentHTML('beforeend', `<div class="schedule__wrapper-item">
                        <div class="schedule__wrapper-item__title">${data.schedule.wrestling.monday[i].title}</div>
                        <div class="schedule__wrapper-item__time">${data.schedule.wrestling.monday[i].time}</div>
                        <div class="schedule__wrapper-item__trainer">${data.schedule.wrestling.monday[i].trainer}</div>
                        <div class="schedule__wrapper-item__age">${data.schedule.wrestling.monday[i].age}</div>
                        <div class="schedule__wrapper-item__button"><a href=https://wa.me/79274441844?text=${readyEncodedMessage}>Записаться</a></div>
                    </div>`);

                }
            }
            setBoxSchedule();
            setWrestSchedule();

            // Listen to clicks on box weekdays
            boxingWeekdays.forEach((item, index) => {
                item.addEventListener('click', (event) => {

                    const day = Object.keys(data.schedule.boxing)[index],
                          dayLength = Object.keys(data.schedule.boxing[day]).length;

                    // clear the timetable
                    boxingWrapper.innerHTML = '';

                    
                    // insert info about a clicked day
                    for (let i = 0; i <= dayLength - 1; i++) {

                        var message = `${data.schedule.boxing[day][i].title} ` + `${data.schedule.boxing[day][i].time} ` + `${data.schedule.boxing[day][i].trainer} ` + `${data.schedule.boxing[day][i].age}`;
                        var encodedMessage = encodeURIComponent(message);
                        var messageStart = '%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%3A%0A';
                        var readyEncodedMessage = messageStart + encodedMessage;

                        boxingWrapper.insertAdjacentHTML('beforeend', `<div class="schedule__wrapper-item">
                            <div class="schedule__wrapper-item__title">${data.schedule.boxing[day][i].title}</div>
                            <div class="schedule__wrapper-item__time">${data.schedule.boxing[day][i].time}</div>
                            <div class="schedule__wrapper-item__trainer">${data.schedule.boxing[day][i].trainer}</div>
                            <div class="schedule__wrapper-item__age">${data.schedule.boxing[day][i].age}</div>
                            <div class="schedule__wrapper-item__button"><a href=https://wa.me/79274441844?text=${readyEncodedMessage}>Записаться</a></div>
                        </div>`);
                    }


                    for (let i = 0; i < boxingWeekdays.length; i++) {
                        // remove all _active classes
                        boxingWeekdays[i].classList.remove('schedule__weekdays-item_active');
                    }
            
                    // ...and give _active class to that element
                    boxingWeekdays[index].classList.add('schedule__weekdays-item_active');

                });
            });

            // Listen to clicks on wrestling weekdays
            wrestlingWeekdays.forEach((item, index) => {
                item.addEventListener('click', (event) => {

                    const day = Object.keys(data.schedule.wrestling)[index];
                    const dayLength = Object.keys(data.schedule.wrestling[day]).length;

                    // clear the timetable
                    wrestlingWrapper.innerHTML = '';

                    // insert info about a clicked day
                    for (let i = 0; i <= dayLength - 1; i++) {

                        var message = `${data.schedule.wrestling[day][i].title} ` + `${data.schedule.wrestling[day][i].time} ` + `${data.schedule.wrestling[day][i].trainer} ` + `${data.schedule.wrestling[day][i].age}`;
                        var encodedMessage = encodeURIComponent(message);
                        var messageStart = '%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%3A%0A';
                        var readyEncodedMessage = messageStart + encodedMessage;

                        wrestlingWrapper.insertAdjacentHTML('beforeend', `<div class="schedule__wrapper-item">
                            <div class="schedule__wrapper-item__title">${data.schedule.wrestling[day][i].title}</div>
                            <div class="schedule__wrapper-item__time">${data.schedule.wrestling[day][i].time}</div>
                            <div class="schedule__wrapper-item__trainer">${data.schedule.wrestling[day][i].trainer}</div>
                            <div class="schedule__wrapper-item__age">${data.schedule.wrestling[day][i].age}</div>
                            <div class="schedule__wrapper-item__button"><a href=https://wa.me/79274441844?text=${readyEncodedMessage}>Записаться</a></div>
                        </div>`);

                    }

                    for (let i = 0; i < wrestlingWeekdays.length; i++) {
                        // remove all _active classes
                        wrestlingWeekdays[i].classList.remove('schedule__weekdays-item_active');
                    }
            
                    // ...and give _active class to that element
                    wrestlingWeekdays[index].classList.add('schedule__weekdays-item_active');

                });
            });

        }
    });


}
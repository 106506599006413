export default function() {

    // CREATE NEWS CARDS
    // and append them into wrapper, with info

    const newsWrapper = document.querySelector('.news-wrapper');

    let data, 
        newsDataLength,
        indexJSONData;

    const request = new XMLHttpRequest();
    request.open('GET', './../db.json');
    request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    request.send();
    request.addEventListener('load', () => {
        if (request.status === 200) {
            data = JSON.parse(request.response);
            newsDataLength = Object.keys(data.news).length;

            // Set the path to the PHP script that fetches the latest images
            const imageUrl = './news.php';

            // Fetch the latest images from the PHP script
            fetch(imageUrl)
                .then(response => response.json())
                .then(latestImages => {
                    for (let i = newsDataLength - 1; i >= newsDataLength - 6; i--) {
                        // Set the background image for the current news card
                        const imageUrl = `./img/mc/news/${latestImages[newsDataLength - 1 - i]}`;
                        newsWrapper.insertAdjacentHTML('beforeend', `
                            <div class="news-wrapper__card">
                                <div class="news-wrapper__card-image" style="background-image: url('${imageUrl}')"></div>
                                <div class="news-wrapper__card-date">${data.news[i].date}</div>
                                <div class="news-wrapper__card-title">${data.news[i].title}</div>
                            </div>
                        `);
                    }
                });


        } else {
            // ВНИМАНИЕ прописать что будет если сервер не ответит
        }
    });





    // MODAL WINDOW FUNCTIONALITY

    const cards = document.querySelectorAll('.news-wrapper__card'),
          modal = document.querySelector('.news-modal'),
          crossModal = document.querySelector('.news-modal .cross'),
          image = document.querySelector('.news-modal__image'),
          date = document.querySelector('.news-modal__date'),
          title = document.querySelector('.news-modal__title'),
          text = document.querySelector('.news-modal__text'),
          imessage = document.querySelector('.imessage'),
          imessageText = document.querySelector('.imessage .from-me'),
          imessagePhone = document.querySelector('.imessage .from-me a'),
          imessageCross = document.querySelector('.imessage__cross'),
          fog = document.querySelector('.fog');


    newsWrapper.addEventListener('click', e => {
        if (e.target && e.target.classList.contains('news-wrapper__card')) {
          // Get the index of the clicked card
          let index = Array.prototype.indexOf.call(e.currentTarget.children, e.target);
          index--;
      
          // Open the modal window with the corresponding news data
          openModal(index);
        }
    });


    // Fn for opening the modal window
    function openModal(index) {

        // put on the background fog
        fog.classList.add('fog_show');
        setTimeout(function() {
            fog.classList.add('fog_active');
        }, 6);

        // show the modal window
        modal.classList.add('news-modal_active');

        // lock document scroll
        document.body.classList.add('scroll_lock');

        // listener for clicks outside of the menu
        setTimeout(function() {
            document.addEventListener('click', close);
        }, 500);


        const request = new XMLHttpRequest();
        request.open('GET', './../db.json');
        request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        request.send();
        request.addEventListener('load', () => {
            if (request.status === 200) {
                data = JSON.parse(request.response);
                newsDataLength = Object.keys(data.news).length;

                // Set the path to the PHP script that fetches the latest images
                const imageUrl = './news.php';

                // Fetch the latest images from the PHP script
                fetch(imageUrl)
                    .then(response => response.json())
                    .then(latestImages => {
                        const imageUrl = `./img/mc/news/${latestImages[index]}`;
                        indexJSONData = newsDataLength - (index + 1);

                        image.style.backgroundImage = `url('${imageUrl}')`;
                        date.innerHTML = data.news[indexJSONData].date;
                        title.innerHTML = data.news[indexJSONData].title;
                        text.innerHTML = data.news[indexJSONData].text;

                        // if there is a video tied in db.json
                        if (data.news[indexJSONData].video) {
                            image.innerHTML = `<iframe width="100%" height="100%" src="${data.news[indexJSONData].video}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

                            // move the cross so it won't overlap the video
                            document.querySelector('.news-modal .cross').style.top = `${(window.getComputedStyle(image)).height}`;
                        }

                        // if there is specified info about background-position Y offset in db.json
                        if (data.news[indexJSONData].bgYOffset) {
                            image.style.backgroundPosition = `center ${data.news[indexJSONData].bgYOffset}`;
                        }
                    });
                      
                    // add an event listener for window resize
                    window.addEventListener('resize', checkViewportWidth);                      
                      

            } else {
                // ВНИМАНИЕ прописать что будет если сервер не ответит
            }
        });

    }

    // Fn for running some checks before calling for closing the modal window
    function close(e) {
        if (modal.classList.contains('news-modal_active') && e.target != modal && e.target != image && e.target != date && e.target != title && e.target != text && e.target != imessage && e.target != imessageText && e.target != imessagePhone && e.target != imessageCross) {
            closeModal();
        }
    }

    // Fn for closing the modal window
    function closeModal() {

        // remove the background fog
        fog.classList.remove('fog_active');
        setTimeout(function() {
            fog.classList.remove('fog_show');
        }, 400);

        // hide the modal window
        modal.classList.remove('news-modal_active');

        // unlock document scroll
        document.body.classList.remove('scroll_lock');

        // remove listener for clicks outside of the menu
        document.removeEventListener('click', close);

        // remove listener for window resize
        window.removeEventListener('resize', checkViewportWidth); 

        // remove the data
        image.style.backgroundImage = ``;
        image.innerHTML = '';
        date.innerHTML = '';
        title.innerHTML = '';
        text.innerHTML = '';

        // reset cross positioning
        document.querySelector('.news-modal .cross').style.top = 'initial';

    }

    // Fn for creen orientation listener
    function checkViewportWidth() {
        var mediaQuery = window.matchMedia('(min-width: 960px) and (max-width: 1199px)');
        if (mediaQuery.matches) {
            console.log('<1199px');
            document.querySelector('.news-modal .cross').style.top = `${(window.getComputedStyle(image)).height}`;
          // viewport width is between 1199px and 960px
        }
      
        mediaQuery = window.matchMedia('(min-width: 640px) and (max-width: 959px)');
        if (mediaQuery.matches) {
            console.log('<959px');
            document.querySelector('.news-modal .cross').style.top = `${(window.getComputedStyle(image)).height}`;
            // viewport width is between 959px and 640px
        }
      
        mediaQuery = window.matchMedia('(min-width: 480px) and (max-width: 639px)');                      
        if (mediaQuery.matches) {
            console.log('<639px');
            document.querySelector('.news-modal .cross').style.top = `${(window.getComputedStyle(image)).height}`;
          // viewport width is between 639px and 480px
        }

        mediaQuery = window.matchMedia('(max-width: 479px)');                      
        if (mediaQuery.matches) {
            console.log('<479px');
            document.querySelector('.news-modal .cross').style.top = `${(window.getComputedStyle(image)).height}`;
          // viewport width is less than 479px
        }
      }
    
    
}
// var $ = require("jquery");
import header from './header';
import trainers from './trainers';
import price from './price';
import schedule from './schedule';
import gallery from './gallery';
import about from './about';
import news from './news';

window.addEventListener('DOMContentLoaded', (event) => {

    header();
    trainers();
    price();
    schedule();
    gallery();
    about();
    // news();
    
});
